<template>
    <v-container>
      <div class="header_holder text-center ma">
        <div class="rounder_top mb-2">
            <div class="card_header ubuntu_b primer">
                Profil
            </div>
        </div>
      </div>
      <v-row dense class="my-8">
          <v-col cols="12">
              <div class="text-center mt-3 mb-3">
                <router-link to="/change_password">
                  <v-btn color="secondary"> 
                    Promjeni lozinku
                  </v-btn>
                </router-link>
              </div>
              <div class="text-center mt-3 mb-3">
                <router-link to="/change_email">
                  <v-btn color="secondary"> 
                    Promjeni email
                  </v-btn>
                </router-link>
              </div>
          </v-col>
      </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      cart_items: 'cart/cartProducts',
      categories: 'products/getMainCategories'
    }),
  },
  beforeCreate(){
    if(this.$store.getters['user/getCredentials'].narucivanje == 0) this.$router.push({ name: 'not_auth' })
    this.$store.dispatch('products/getMainCategories', /*this.$route.params.category_id*/)
  }
}
</script>
<style scoped>
  .category, .category_btn{
      width:100%
  }
</style>